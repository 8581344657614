import { lighten } from "polished";

// themeColor: "#8B0000",
// backgroundColor: "#2b2e3c"

//  themeColor: "#FFF4F4",
//   backgroundColor: "#3226FF"
// #9B80D7
// #48A7E4

const colors = {
  primary: "#48A7E4", // Color for buttons or links
  secondary: "#9B80D7",
  primaryLight: lighten(0.05, "#48A7E4"),
  bg: "#2b2e3c", // Background color
  grey: {
    dark: "rgba(255, 255, 255, 0.9)",
    default: "rgba(255, 255, 255, 0.7)",
    light: "rgba(255, 255, 255, 0.5)",
    ultraLight: "rgba(255, 255, 255, 0.25)"
  },
  dark: {
    dark: "rgba(0, 0, 0, 0.9)",
    default: "rgba(0, 0, 0, 0.7)",
    light: "rgba(0, 0, 0, 0.5)",
    ultraLight: "rgba(0, 0, 0, 0.25)"
  },
  white: "white"
};

const transitions = {
  normal: "0.5s"
};

const fontSize = {
  small: "0.9rem"
};

const fontFamily = {
  // serif: `'Bitter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', serif`,
  sansSerif: `'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif`
};

const breakpoints = {
  tablet: "1200px",
  phone: "600px"
};

const theme = {
  colors,
  transitions,
  fontSize,
  breakpoints,
  fontFamily,
  maxWidth: "800px",
  baseFontSize: "18px"
};

export default theme;
